window.Client = {
	init: function() {
		// Do something on initialization
	},

	onRender: function(screenId) {
		// Do something when the flow renders
	}
}

//TRACKING UTM PARAMS AND OTHER INFO

// Get current date
var currentDate = new Date();

// Get year, month, and day from the current date
var year = currentDate.getFullYear();
var month = String(currentDate.getMonth() + 1).padStart(2, '0');
var day = String(currentDate.getDate()).padStart(2, '0');

// Format the date as "yyyy-mm-dd"
var formattedDate = year + '-' + month + '-' + day;

// Get URL query parameters
const urlParams = new URLSearchParams(window.location.search);

// Get initial visit url 
const initialLandingPageUrl = window.location.href;

// Calculate expiration date as one month from now
const now = new Date();
const expires = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);

// Check if the "first_visit_date" cookie exists
const firstVisitDateCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('first_visit_date='));
if (!firstVisitDateCookie) {
  // If "first_visit_date" cookie doesn't exist, set it with the current date
  document.cookie = `first_visit_date=${formattedDate}; expires=${expires.toUTCString()}; domain=.keyword.com; path=/;`;
}

// Check if the "_uc_initial_landing_page" cookie exists
const initialLandingPageUrlCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('_uc_initial_landing_page='));
if (!initialLandingPageUrlCookie) {
  // If "first_visit_date" cookie doesn't exist, set it with the current date
  document.cookie = `_uc_initial_landing_page=${initialLandingPageUrl}; expires=${expires.toUTCString()}; domain=.keyword.com; path=/;`;
}

// Define an array of parameter names to set as cookies
const paramNames = [
  { key: '_uc_utm_source', paramName: 'utm_source' },
  { key: '_uc_utm_medium', paramName: 'utm_medium' },
  { key: '_uc_utm_id', paramName: 'utm_id' },
  { key: '_uc_utm_term', paramName: 'utm_term' },
  { key: '_uc_utm_mt', paramName: 'utm_mt' },
  { key: '_uc_utm_device', paramName: 'utm_device' },
  { key: '_uc_utm_campaign', paramName: 'utm_campaign' },
  { key: '_uc_gclid', paramName: 'gclid' },
  { key: '_uc_fbclid', paramName: 'fbclid' },
  { key: '_uc_msclkid', paramName: 'msclkid' },
  { key: '_uc_twclid', paramName: 'twclid' },
  { key: '_uc_rdtclid', paramName: 'rdtclid' }
];


// Check if any utm parameter cookie already exists
const utmCookiesExist = document.cookie.split(';').some(cookie => cookie.trim().startsWith(`_uc_utm`));

// Loop through the other parameter names and set cookies with corresponding values, dynamic expiration date, and domain attribute
paramNames.forEach(param => {
  const paramCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith(`${param.key}=`));
  const paramValue = urlParams.get(param.paramName);
  
  if (paramValue && !paramCookie && (!utmCookiesExist || !param.key.startsWith('_uc_utm'))) {
    document.cookie = `${param.key}=${paramValue}; expires=${expires.toUTCString()}; domain=.keyword.com; path=/;`;
  }
});

